import './style.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import maplibregl from 'maplibre-gl';
import { Map } from 'maplibre-gl';
import { Protocol } from 'pmtiles';
import layers from 'protomaps-themes-base';

let protocol = new Protocol();
maplibregl.addProtocol("pmtiles", protocol.tile);

const map = new Map({
  container: 'map',
  bounds: [[5.98865807458, 47.3024876979], [15.0169958839, 54.983104153]],
  style: {
    version: 8,
    glyphs:'./fonts/{fontstack}/{range}.pbf',
    sources: {
      basemap: {
        type: 'vector',
        url: 'pmtiles://https://maps.heidler.info/germany.pmtiles',
        // url: 'pmtiles://germany.pmtiles',
        attribution: '<a href="https://protomaps.com">Protomaps</a> © <a href="https://openstreetmap.org">OpenStreetMap</a>',
        promoteId: 'index',
      },
      directions: {
        type: 'geojson',
        lineMetrics: true,
        data: './directions.geojson',
      },
    },
    layers: [
      ...layers('basemap', 'light'),
    ],
  },
});

map.addControl(new maplibregl.NavigationControl({showCompass: true}), 'bottom-right');
map.addControl(new maplibregl.FullscreenControl());
